import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Members from "../components/Members"
import UnlockContext from "../contexts/UnlockContext"


const MembershipContent = ({ locks }) => {
  const { isMember, becomeMember, address, signIn } = React.useContext(UnlockContext)
  return (
    <>
      <h1>Membership</h1>
      <p>
        I have long believed that <a href="/the-curse/">the web is cursed</a>{" "}
        if we keep using attention as the core monetization metric. This
        race is what brought us information overload, clickbait, endless
        slideshows and fake news…
      </p>

      <p>
        Today though, we’re seeing a shift as people are (rightfully) trying to
        take back their time. Simultaneously, more than ever, the{" "}
        <a href="https://medium.com/unlock-protocol/the-end-of-the-ad-supported-web-d4d093fb462f">
          ad supported web is being challenged
        </a>{" "}
        and many creators, both big (Netflix, Spotify, New York Times,
        Washington Post…) and small (though Medium, Patreon or Substack…) are
        moving to revenue streams from their consumers, rather than from
        hypothetical benevolent advertisers.
      </p>

      <p>
        <a href="https://unlock-protocol.com">Unlock</a>, a company I founded is
        building a <strong>protocol for memberships</strong> so we can we build a web-scale approach to
        memberships which works for creators and consumers who want convenience
        over anything else.
      </p>



      <h2>Benefits</h2>

      <p>Here is a list of current benefits, but please, expect for me to add more!</p>
      <ul>
        <li>Full access to all content on this blog</li>
        <li>More soon!</li>
        <li>...</li>
      </ul>

      <p>
        I will write as often as possible, what I believe to be unique content
        and a good representation of my current thinking!
      </p>

      <h3>How to get a membership</h3>

      {!address && <p> First, you need to <button onClick={() => signIn()} role="button" style={{ marginLeft: "auto" }} >Sign in</button>.</p>}

      {address && isMember === "no" && (
        <p>Then, <button onClick={becomeMember}>Become a member now!</button>. </p>
      )}
      {address && isMember === "yes" && (
        <p>
          🎉 It looks like you're one of the members of this blog! Thank you! 🎉
        </p>
      )}


      <h3>Technical details</h3>
      <p>
        Getting a membership means that you will receive a subscription NFT for the contract located at {Object.keys(locks)} on the <a href="https://docs.base.org/">Base network</a>. You can inspect the contract using <a href="https://basescan.org/address/0xba6beb73cdaec34957290cb7e3522187f8382b55">Block Explorer</a>.
      </p>
      <p>This contract is an <a href="https://unlock-protocol.com/">Unlock Protocol</a> lock and anyone can deploy their own contract pretty easily!</p>

      <p>The lock smart contract guarantees that you can cancel at any time and get a
        pro-rated refund (but please reach out directly to me if you want a full
        refund).
      </p>

      <p>
        More importantly, if the price is too high for you, please do reach out
        as well and I’ll grant you a key for free.
      </p>


      <Members />
    </>
  )
}

const Membership = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const locks = {}
  data.site.siteMetadata.locks.forEach(lock => {
    locks[lock.address] = {
      network: lock.network,
    }
  })

  return (
    <Layout locks={locks} location={location} title={siteTitle}>
      <Seo title="Membership" />
      <MembershipContent locks={locks} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        locks {
          network
          address
        }
      }
    }
  }
`

export default Membership
